import { Offcanvas } from '/js/vendor/bootstrap.custom.js'


document.addEventListener('DOMContentLoaded', function() {
	const lkSidebar = document.getElementById('lk-sidebar')

	if (lkSidebar) {
		enquire.register('screen and (max-width: 991.98px)', {
			match: function () {
				lkSidebar.classList.add("offcanvas","offcanvas-end")
				lkSidebar.setAttribute('tabindex', '-1');
			},
			unmatch: function () {
				var lks = Offcanvas.getInstance(lkSidebar)
				if (lks) lks.hide();

				lkSidebar.classList.remove("offcanvas","offcanvas-end")
				lkSidebar.removeAttribute('tabindex');
			}
		})
	}
})
